<template>
    <div>
        <b-row align-h="center" v-if="state === 'initializing'">
            <b-spinner></b-spinner>
        </b-row>
        <b-row class="mb-4" v-if="state !== 'initializing'">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Surveys</h4>
                        </b-col>
                        <b-col class="text-right">
                            <b-button variant="red" size="sm" squared @click="deleteCurrentSurvey()">Delete Survey</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Procedure Name</label>
                            <b-form-input v-model="selectedSurvey.description"></b-form-input>
                        </b-col>
                        <!-- <b-col cols="4">
                            <label>Procedure Number</label>
                            <b-form-input v-model="selectedSurvey.procedureNumber"></b-form-input>
                        </b-col> -->
                    </b-row>
                    <hr class="mx-3" />
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="primary" squared class="mr-2" @click="openAddNewCategoryModal()">Add Category</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                                :busy="tableData.isLoading" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>

                                <template #cell(surveyQuestions)="row">
                                    {{row.item.surveyQuestions.length}}
                                </template>


                                <!-- <template #cell(imageFile)="row">
                                    <b-row align-v="center">
                                        <b-badge :variant="row.item.imageFile === null ? 'red' : 'green'">{{row.item.imageFile === null ? 'No' : 'Yes'}}</b-badge>
                                        <b-icon-eye @click="openModalImage(row.item.imageFile)" size="lg" v-if="row.item.imageFile !== null" class="ml-2"></b-icon-eye>
                                    </b-row>
                                </template> -->

                                <template #cell(actions)="row">
                                    <b-row align-v="center" align-h="end">
                                        <b-icon-eye @click="row.toggleDetails" size="lg" v-if="!row.detailsShowing"></b-icon-eye>
                                        <b-icon-eye-slash @click="row.toggleDetails" size="lg" v-if="row.detailsShowing"></b-icon-eye-slash>
                                        <b-button @click="addQuestionToCategoryModal(row.item)" variant="primary" size="sm" class="ml-2">
                                            Add Question
                                        </b-button>
                                        <b-button @click="editCategoryModal(row.item)" variant="primary" size="sm" class="ml-2">
                                            Edit
                                        </b-button>
                                        <b-button @click="deleteCategoryModal(row.item)" variant="red" size="sm" class="ml-2">
                                            Remove
                                        </b-button> 
                                    </b-row>
                                </template>
                                <template #row-details="row">
                                    <b-card class="questions-card">
                                        <b-table striped hover :items="row.item.surveyQuestions" :fields="tableData.questionsTableColumns" sort-icon-left>
                                            <template #cell(actions)="row">
                                                <b-row align-v="center" align-h="end">
                                                    <b-button @click="openQuestionToCategoryModal(row.item)" variant="primary" size="sm" class="ml-2">
                                                        Edit
                                                    </b-button>
                                                    <b-button @click="deleteQuestionModal(row.item)" variant="red" size="sm" class="ml-2">
                                                        Remove
                                                    </b-button> 
                                                </b-row>
                                            </template>
                                        </b-table>
                                    </b-card>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="red" squared class="mr-2" @click="backToPrevious()">Back</b-button>
                            <b-button variant="primary" squared @click="saveSurvey()">
                                <b-spinner small v-if="state === 'loading'"></b-spinner>Save</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-modal id="show-image-modal" hide-footer size="xl" no-header @close="closeSearchModal()">
            <b-row>
                <b-col>
                    <img v-if="modalImage.base64Data !== null" :src="returnFileObject(modalImage)" alt="" 
                        class="modal-images">
                    <p class="text-center" v-if="modalImage.base64Data === null" >Could not load image</p>
                </b-col>
            </b-row>
        </b-modal>
        
        <b-modal id="category-modal" hide-footer size="xl" @close="closeCategoryModal()">
            <template #modal-header="{}">
                <h5>Category</h5>
            </template>
            <b-row>
                <b-col cols="12">
                    <label>Category Name</label>
                    <b-form-input v-model="selectedCategory.description"></b-form-input>
                </b-col>
            </b-row>
            <!-- <b-row>
                <b-col cols="12">
                    <div class="material-box">
                        <label class="material-label">Category Image</label>
                        <b-form-file
                            accept="image/*"
                            v-model="selectedCategory.imageFile"
                            @change="filesUploaded"
                            placeholder="Choose a file..."
                            drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </div>
                </b-col>
            </b-row> -->
            <b-row>
                <b-col cols="12" class="text-right mt-3">
                    <b-button variant="red" @click="closeCategoryModal" squared class="mr-2">Cancel</b-button>
                    <b-button variant="primary" @click="saveCategory()" squared>Save</b-button>
                </b-col>
            </b-row>
        </b-modal>
        
        <b-modal id="question-modal" hide-footer size="xl" @close="closeCategoryModal()">
            <b-row>
              <b-col cols="12"  v-for="(item, index) in messages" :key ="index">
                <b-alert show dismissible variant="warning">{{item}}</b-alert>
              </b-col>
            </b-row>
            <b-row>
                <b-col cols="8">
                    <label>Question</label>
                    <b-form-input v-model="selectedQuestion.question"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Answer Type</label>
                    <b-form-select v-model="selectedQuestion.answerType" :disabled="state === 'loading'">
                        <b-form-select-option v-for="(item, index) in answerTypes" :key="index" :value="item">{{item}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label>Acceptable Answer</label>
                    <b-input-group>
                        <b-form-input v-model="newAnswer"></b-form-input>
                        <b-input-group-append>
                            <b-button size="sm" text="Button" variant="primary" @click="addAnswerToQuestion()">Add Answer</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-table striped hover :items="answersTableData.dataSource" :fields="answersTableData.tableColumns" sort-icon-left>
                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="deleteAnswers(row.item)" variant="red" size="sm" class="ml-2">
                                    Remove
                                </b-button> 
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <b-form-checkbox
                        v-model="selectedQuestion.required"
                        value="true"
                        unchecked-value="false"
                        >Required
                    </b-form-checkbox>
                    
                </b-col>
                <b-col class="text-right">
                    <b-button variant="primary" size="sm" squared @click="saveQuestion()">Save</b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal id="category-delete-modal" hide-footer no-header @close="closeDeleteCategoryModal()">
            <b-row>
                <b-col cols="12" class="text-center">
                    Are you sure you want to delete this category?
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col class="text-center">
                    <b-button variant="red" size="sm" class="mr-2" squared @click="closeDeleteCategoryModal()">No</b-button>
                    <b-button variant="primary" size="sm" squared @click="completeDeleteCategory()">Yes</b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal id="question-delete-modal" hide-footer no-header @close="closeDeleteQuestionModal()">
            <b-row>
                <b-col cols="12" class="text-center">
                    Are you sure you want to delete this question?
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col class="text-center">
                    <b-button variant="red" size="sm" class="mr-2" squared @click="closeDeleteQuestionModal()">No</b-button>
                    <b-button variant="primary" size="sm" squared @click="completeDeleteQuestion()">Yes</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data: () => ({
        state: 'initializing',
        answerTypes: [],
        newAnswer: null,
        messages: [],
        modalImage: {
            base64Data: null
        },
        selectedQuestion: {
            answerType: null
        },
        selectedCategory: {
            surveyID: null,
            surveyCategoryID: 0,
            description: null,
            // imageFile:null
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                // {
                //     label: 'Image Included',
                //     key: 'imageFile',
                //     sortable: false
                // },
                {
                    label: 'Question Count',
                    key: 'surveyQuestions',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ],
            questionsTableColumns:[
                {
                    label: 'Description',
                    key: 'question',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Answer Type',
                    key: 'answerType',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        answersTableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Answer',
                    key: 'acceptableAnswer',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        }
    }),
    created(){
        if (this.selectedSurvey === null) {
            this.$router.back()            
        }
        //console.log('surevy', this.selectedSurvey)
        if (this.selectedSurvey && this.selectedSurvey.surveyID !== 0) {
            this.getSurveyInformation()
            .then(() =>{
                //console.log('test',this.selectedSurvey)
                this.state = 'show'
                this.tableData.dataSource = this.selectedSurvey.surveyCategories
                this.tableData.isLoading = false
            })

            this.getSurveyQuestionAnswerTypes()
            .then((res) => {
                this.answerTypes = res.data.result
            })
        }
        else{
            this.state = 'show'
            this.tableData.isLoading = false
        }
    },
    methods:{
        ...mapActions(['getSurveyInformation', 'updateSurvey', 'updateSurveyCategory', 'getSurveyQuestionAnswerTypes', 
            'deleteSurvey', 'updateSurveyQuestion', 'deleteSurveyCategory']),
        ...mapActions('notificationService', ['addSuccessMessage']),
        openModalImage(image){
            this.modalImage = image
            this.$root.$emit('bv::show::modal', 'show-image-modal', '#btnShow')
        },
        returnFileObject(item){
            let base64 = 'data:' + item.contentType +';base64,'+ item.base64Data
            return base64
        },
        addAnswerToQuestion(){
            this.selectedQuestion.surveyAcceptableAnswers.push({surveyAcceptableAnswerId: 0, acceptableAnswer: this.newAnswer})
            this.newAnswer = null
        },
        deleteAnswers(row) {
            const index = this.answersTableData.dataSource.findIndex(answer => answer.acceptableAnswer === row.acceptableAnswer);
            
            if (index >= 0) {
                this.answersTableData.dataSource.splice(index, 1);
            }
        },
        saveCategory(){
            this.selectedCategory.surveyID = this.selectedSurvey.surveyID
            this.tableData.isLoading = true
            this.$store.commit('setNewCategoryRequest', this.selectedCategory)
            this.updateSurveyCategory()
            .then((res) =>{
                this.closeCategoryModal()
                //console.log('seave response', res.data.result)
                //console.log('seave response', this.selectedCategory)
                let response = res.data.result
                if (this.selectedCategory.surveyCategoryID === 0) {
                    //console.log('in the if')
                    if (this.selectedCategory.imageFile !== null) {
                        
                        let commaIndex = this.selectedCategory.imageFile.base64Data.indexOf(",")
                        response.imageFile = {
                            base64Data: this.selectedCategory.imageFile.base64Data.substr(commaIndex+1),
                            contentType: this.selectedCategory.imageFile.base64Data.substr(0, commaIndex),
                            // fileName: this.selectedCategory.imageFile.fileName
                        }
                    }
                    this.selectedSurvey.surveyCategories.push(response)
                    //console.log('testing', this.selectedSurvey)
                }
                else{
                    if (this.selectedCategory.imageFile !== null) {
                        let commaIndex = this.selectedCategory.imageFile.base64Data.indexOf(",")
                        if (commaIndex !== -1) {
                            
                            this.selectedCategory.imageFile = {
                                base64Data: this.selectedCategory.imageFile.base64Data.substr(commaIndex+1),
                                contentType: this.selectedCategory.imageFile.base64Data.substr(0, commaIndex),
                                // fileName: this.selectedCategory.imageFile.fileName
                            }
                        }
                        
                    }
                }
                
                this.selectedCategory = {
                    surveyID: null,
                    surveyCategoryID: 0,
                    description: null,
                    imageFile: null
                }
                    //console.log('testing', this.selectedSurvey)
                this.tableData.isLoading = false
            })
            .catch(() => {
                this.tableData.isLoading = false
                this.closeCategoryModal()
            })
        },
        saveQuestion(){
            if (this.selectedQuestion.question === null || this.selectedQuestion.question === '') {
                this.messages.push('You need to enter a question')
                return;
            }
            if (this.selectedQuestion.answerType === null || this.selectedQuestion.answerType === '') {
                this.messages.push('You need to select an answer type')
                return;
            }
            this.selectedQuestion.surveyID = this.selectedSurvey.surveyID
            this.$store.commit('setQuestionForCategoryRequest', this.selectedQuestion)
            this.updateSurveyQuestion()
            .then((res) =>{
                if (this.selectedQuestion.questionId === 0) {
                    this.selectedQuestion.questionId = res.data
                    let item = this.selectedSurvey.surveyCategories.findIndex(element => element.surveyCategoryID === this.selectedQuestion.categoryId)
                    //console.log('item', item)
                    this.selectedSurvey.surveyCategories[item].surveyQuestions.push(this.selectedQuestion)
                }
                this.closeQuestionModal()
                this.selectedQuestion = {
                    question : null,
                    answerType: null
                }
            })
            .catch(() => {
                this.closeQuestionModal()
            })
        },
        saveSurvey(){
            this.state = 'loading'
            this.updateSurvey()
            .then(() => {
                this.$router.back()
                this.state = 'show'
            })
            .catch(() => {
                this.state = 'show'
            })
        },
        openAddNewCategoryModal(){
            this.selectedCategory = {
                surveyID: null,
                surveyCategoryID: 0,
                description: null,
                imageFile: null
            }
            this.$root.$emit('bv::show::modal', 'category-modal', '#btnShow')
        },
        closeCategoryModal(){
            this.$root.$emit('bv::hide::modal', 'category-modal', '#btnShow')
        },
        addQuestionToCategoryModal(rowItem){
            let item = {
                active: true,
                answerType: null,
                surveyAcceptableAnswers: [],
                categoryId: rowItem.surveyCategoryID,
                surveyID: rowItem.surveyID,
                question: null,
                questionId: 0,
                required: false
            }
            this.selectedQuestion = item
            this.answersTableData.dataSource = item.surveyAcceptableAnswers
            this.$root.$emit('bv::show::modal', 'question-modal', '#btnShow')
        },
        openQuestionToCategoryModal(rowItem){
            this.selectedQuestion = rowItem
            this.answersTableData.dataSource = rowItem.surveyAcceptableAnswers
            this.$root.$emit('bv::show::modal', 'question-modal', '#btnShow')
        },
        closeQuestionModal(){
            this.$root.$emit('bv::hide::modal', 'question-modal', '#btnShow')
        },
        editCategoryModal(rowItem){
            this.selectedCategory = rowItem
            this.$root.$emit('bv::show::modal', 'category-modal', '#btnShow')
        },
        deleteCategoryModal(rowItem){
            this.selectedCategory = rowItem
            this.$root.$emit('bv::show::modal', 'category-delete-modal', '#btnShow')
        },
        closeDeleteCategoryModal(){
            this.selectedCategory = {}
            this.$root.$emit('bv::hide::modal', 'category-delete-modal', '#btnShow')
        },
        completeDeleteCategory(){
            this.$store.commit('setDeleteCategoryRequest', this.selectedCategory)
            this.deleteSurveyCategory()
            .then(() => {
                let item = this.selectedSurvey.surveyCategories.findIndex(element => element.surveyCategoryID === this.selectedCategory.surveyCategoryID)
                this.selectedSurvey.surveyCategories.splice(item, 1)
                this.closeDeleteCategoryModal()
            })
            .catch(() => {
                this.closeDeleteCategoryModal()
            })
        },
        completeDeleteQuestion(){
            this.selectedQuestion.surveyID = this.selectedSurvey.surveyID
            this.selectedQuestion.active = false
            this.$store.commit('setQuestionForCategoryRequest', this.selectedQuestion)
            this.updateSurveyQuestion()
            .then(() =>{
                let item = this.selectedSurvey.surveyCategories.findIndex(element => element.surveyCategoryID === this.selectedQuestion.categoryId)
                let question = this.selectedSurvey.surveyCategories[item].surveyQuestions.findIndex(element => element.questionId === this.selectedQuestion.questionId)
                this.selectedSurvey.surveyCategories[item].surveyQuestions.splice(question, 1)

                this.selectedQuestion = null
                this.closeDeleteQuestionModal()
                this.addSuccessMessage('Question removed')
            })
            .catch(() => {
                this.closeDeleteQuestionModal()
            })
        },
        deleteQuestionModal(rowItem){
            this.selectedQuestion = rowItem
            this.$root.$emit('bv::show::modal', 'question-delete-modal', '#btnShow')
        },
        closeDeleteQuestionModal(){
            this.selectedQuestion = {}
            this.$root.$emit('bv::hide::modal', 'question-delete-modal', '#btnShow')
        },
        backToPrevious(){
            this.$router.back()
        },
        filesUploaded(e){
            const selectedDocument = e.target.files[0]
                if (selectedDocument.size > 20000000) {
                        this.errors.push({message: 'Document to big to be uploaded'})
                    return
                }
            this.createBase64File(selectedDocument)
            this.uploadFile = null
        },
        createBase64File(fileObject){
            const reader = new FileReader()

            reader.onload = (e) => {
                this.file1 = e.target.result

                let request = {
                    fileName: fileObject.name,
                    base64Data: this.file1
                }

                this.selectedCategory.imageFile = request
            }

            reader.readAsDataURL(fileObject)
        },
        deleteCurrentSurvey(){
            this.state = 'loading'
            this.deleteSurvey()
            .then(() => {
                this.$router.back()
                this.state = 'show'
            })
            .catch(() => {
                this.state = 'show'
            })
        }
    },
    computed:{
        ...mapState(['selectedSurvey'])
    }
}
</script>